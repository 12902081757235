import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";
import { useMemo } from "react";
import Clap from "../assets/emoji_clap.svg";
import Like from "../assets/emoji_thumbs.svg";
import Confused from "../assets/emoji_monocle.svg";
import RainbowFlag from "../assets/emoji_rainbow-flag.svg";
import { EmojiReactions } from "../Reactions/types";

export const useGetBroadcastEmojiTypes = () => {
  const { featureEnabled } = useEventSplitTreatment(
    EventFeatures.RAINBOW_FLAG_EMOJI,
  );

  const emojiOptionsWithRainbow = [
    { name: EmojiReactions.CLAP, imageType: Clap },
    { name: EmojiReactions.LIKE, imageType: Like },
    { name: EmojiReactions.RAINBOW, imageType: RainbowFlag },
  ];

  const emojiOptionsWithConfused = [
    { name: EmojiReactions.CLAP, imageType: Clap },
    { name: EmojiReactions.LIKE, imageType: Like },
    { name: EmojiReactions.CONFUSED, imageType: Confused },
  ];

  const emojiTypes = useMemo(
    () => (featureEnabled ? emojiOptionsWithRainbow : emojiOptionsWithConfused),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featureEnabled],
  );

  return { emojiTypes };
};
