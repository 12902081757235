import { useSelector } from "react-redux";
import { Container } from "@remo-co/ui-core/src/components/Container";
import ReactedEmoji from "../ReactedEmoji/ReactedEmoji";
import { getAnimationData } from "../Reactions/utils";
import { EmojiReactions } from "../Reactions/types";
import { selectIsRemojiEnabled } from "../../event/selectors";
import { useGetBroadcastEmojiTypes } from "../hooks";

interface IEmojiType {
  name: EmojiReactions;
  imageType: string;
}

const EmojiButtonContainer = () => {
  const isRemojiEnabled = useSelector(selectIsRemojiEnabled);
  const { emojiTypes } = useGetBroadcastEmojiTypes();
  if (!isRemojiEnabled) {
    return null;
  }

  return (
    <Container
      flex
      justifyContent="space-between"
      data-testid="emoji-button-container"
    >
      {emojiTypes.map((emoji: IEmojiType) => (
        <ReactedEmoji
          key={emoji.name}
          type={emoji.name}
          imageType={emoji.imageType}
          animationData={getAnimationData(emoji.name)}
        />
      ))}
    </Container>
  );
};

export default EmojiButtonContainer;
