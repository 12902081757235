export interface IEmojiReactionPayload {
  id: string;
  reactionType: EmojiReactions;
  time: number;
  userId: string;
}

export enum EmojiReactions {
  CLAP = "clap",
  CONFETTI = "confetti",
  CONFUSED = "confused",
  HEART = "heart",
  LAUGH = "laugh",
  LIKE = "like",
  RAINBOW = "rainbow",
}
