import { Container } from "@remo-co/ui-core/src/components/Container";
import { emojiTypes } from "modules/emoji/Reactions/EmojiReaction";
import useEventSplitTreatment from "modules/split/useEventSplitTreatment";
import { EventFeatures } from "services/splitService/features";
import { useMemo } from "react";
import { ConversationEmoji } from "../ConversationEmoji";

export const ConversationEmojiContainer = (): JSX.Element => {
  const { featureEnabled } = useEventSplitTreatment(
    EventFeatures.RAINBOW_FLAG_EMOJI,
  );

  const emojis = useMemo(() => {
    if (featureEnabled) {
      return Object.keys(emojiTypes);
    }
    return Object.keys(emojiTypes).filter((name) => name !== "rainbow");
  }, [featureEnabled]);

  return (
    <Container
      flex
      justifyContent="space-between"
      data-testid="emoji-button-container"
    >
      {emojis.map((name) => (
        <ConversationEmoji key={name} type={name} />
      ))}
    </Container>
  );
};
